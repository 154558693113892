import { LZSPARouter } from "@eventbuilder-utils/lzspa";
import "external-svg-loader";

import { getRegistrantKey } from "./utils";

import "./scss/styles.scss";

const skipLink = document.querySelector("a.skip-to-main-content-link");
skipLink.addEventListener("keydown", (event) => {
    if (event.keyCode !== 32) return;
    event.preventDefault();
    event.target.click();
});
skipLink.addEventListener("click", (event) => {
    event.preventDefault();
    const element = document.querySelector("#maincontent") || document.querySelector("main");
    element.scrollIntoView(true);
});

LZSPARouter
    .on("lzspa:router.start", (event) => {
        document.getElementById("loadingSpinner").style.removeProperty("display");
    })
    .on("lzspa:router.end", (event) => {
        document.getElementById("loadingSpinner").style.setProperty("display", "none"); //.visibility = (activeAPICalls > 0 ? "visible" : "hidden");
    })
    .on("lzspa:router.notfound", (event) => {
        console.error(event);
        //LZSPA.navigate("/");
    })
    .on("lzspa:router.accessdenied", (event) => {
        if (/\/events\/[0-9a-zA-Z]+\/certificate/i.test(new URL(window.location.href).pathname)) {
            LZSPARouter.navigate(`/events/${/\/events\/([0-9a-zA-Z]+)\/certificate/i.exec(new URL(window.location.href).pathname)[1]}`);
            return;
        }
        console.error(event);
        //LZSPA.navigate("/");
    })
    .initialize({
        routes: [
            //{ path: "/home",                            component: () => import("./components/home") },
            { path: "/events/:event/console",           component: () => import("./components/event-console"),          canActivate: (params) => !!getRegistrantKey(params.event) },
            { path: "/events/:event/survey",            component: () => import("./components/event-survey"),           canActivate: (params) => !!getRegistrantKey(params.event) },
            { path: "/events/:event/certificate",       component: () => import("./components/event-certificate"),      canActivate: (params) => !!getRegistrantKey(params.event) },
            { path: "/events/:event/edit",              component: () => import("./components/event-registration-edit"),canActivate: (params) => !!getRegistrantKey(params.event) },
            { path: "/events/:event",                   component: () => import("./components/event-detail") },
            { path: "/me/verify",                       component: () => import("./components/registrant-verify"),      canActivate: (params) => !!(new URL(window.location.href).searchParams.get("code")) },
            { path: "/me",                              component: () => import("./components/registrant-detail"),      canActivate: (params) => !!getRegistrantKey(params.event) },
            { path: "/.*",                              component: () => import("./components/page") }
        ],
        element: document.body.querySelector("main"),
        debug: true,
        disablelinktrap: true
    });

try {
    var activeUrl = new URL(window.location);
    if (activeUrl.searchParams.get("embed") == "true") {
        document.querySelector("body > div.topbar").style.setProperty("display", "none");
        document.querySelector("body > footer").style.setProperty("display", "none");
    }
} catch (error) {
    console.error(error);
}

/*
setTimeout(() => {
    document.getElementById("loadingSpinner").style.visibility = "hidden";
}, 1000);
*/